<template>
  <div class="layout">
    <div
      class="border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between"
    >
      <h3 class="text-lg font-medium leading-6 text-gray-900">Subscriptions</h3>
      <div class="flex">
        <div class="mt-3 sm:mt-0 sm:ml-4 sm:mr-4">
          <label for="search_organizations" class="sr-only">Search</label>
          <div class="flex rounded-md shadow-sm">
            <div class="relative flex-grow focus-within:z-10">
              <div
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
              >
                <SearchIcon class="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                v-model="search"
                name="search_organizations"
                id="search_organizations"
                class="search hidden w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:block sm:text-sm"
                placeholder="Search"
              />
            </div>
            <Menu>
              <div class="relative">
                <MenuButton
                  type="button"
                  class="focus:outline-none relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                >
                  <SortAscendingIcon class="h-5 w-5 text-gray-400" />
                  <span class="ml-2">Sort</span>

                  <ChevronDownIcon
                    class="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"
                  />
                </MenuButton>
                <transition
                  enter-active-class="transition duration-100 ease-out"
                  enter-from-class="transform scale-95 opacity-0"
                  enter-to-class="transform scale-100 opacity-100"
                  leave-active-class="transition duration-75 ease-out"
                  leave-from-class="transform scale-100 opacity-100"
                  leave-to-class="transform scale-95 opacity-0"
                >
                  <MenuItems
                    class="focus:outline-none absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white text-left shadow-lg ring-1 ring-black ring-opacity-5"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="sort-menu"
                  >
                    <div class="py-1" role="none">
                      <MenuItem>
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          >Name</a
                        >
                      </MenuItem>
                      <MenuItem>
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          >Date modified</a
                        >
                      </MenuItem>
                      <MenuItem>
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          >Date created</a
                        >
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </div>
            </Menu>
          </div>
        </div>
        <button
          type="button"
          @click="drawer.create = true"
          class="order-0 focus:outline-none inline-flex items-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 sm:order-1 sm:ml-3"
        >
          Create
        </button>
      </div>
    </div>

    <div
      class="flex flex-col"
      v-if="subscriptions.value !== undefined && subscriptions.value.length > 0"
    >
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div
            class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
          >
            <pagination :pagination="pagination" @changePage="onChangePage" />

            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Organization
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    By
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    On
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="subscription in subscriptions.value"
                  :key="subscription.Id"
                >
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div
                      class="text-sm text-gray-900"
                      v-if="subscription.Organization !== null"
                    >
                      <router-link to="/admin/organisations">{{
                        subscription.Organization.Name
                      }}</router-link>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm text-gray-900">
                      {{ subscription.CreatedBy.FullName }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm text-gray-900">
                      {{ subscription.Created }}
                    </div>
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
                  >
                    <button
                      type="button"
                      v-tippy="{
                        content: 'Edit',
                        animation: 'scale',
                        animateFill: false,
                        theme: 'tomato',
                      }"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800 focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    >
                      <PencilIcon class="h-5 w-5 flex-shrink-0 text-gray-400" />
                    </button>
                    <button
                      type="button"
                      v-tippy="{
                        content: 'Cancel Subscription',
                        animation: 'scale',
                        animateFill: false,
                        theme: 'tomato',
                      }"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-red-400 shadow-sm hover:text-red-800 focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    >
                      <XIcon class="h-5 w-5 flex-shrink-0 text-red-400" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from "vue";

import {
  XIcon,
  SearchIcon,
  SortAscendingIcon,
  ChevronDownIcon,
  PencilIcon,
} from "@heroicons/vue/outline";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

import API from "@/api";
import Pagination from "@/common/components/pagination";
import { directive } from "vue-tippy";

export default defineComponent({
  components: {
    XIcon,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    SearchIcon,
    SortAscendingIcon,
    ChevronDownIcon,
    Pagination,
    PencilIcon,
  },

  directives: {
    tippy: directive,
  },

  setup() {
    const state = reactive({
      count: 0,
    });

    let pagination = toRefs({
      page: 1,
      pageSize: 10,
      totalResults: null,
    });

    let subscriptions = reactive([]);

    const onChangePage = (page) => {
      console.log({ page });
      pagination.page = page;

      fetchSubscriptions(page);
    };

    const fetchSubscriptions = async (page) => {
      console.log({ page });
      let results = await API.Admin.Subscriptions.get(page, 10);
      console.log({ results });

      subscriptions.value = results.data.List;
      pagination.page = results.data.Page;
      pagination.pageSize = results.data.PageSize;
      pagination.totalResults = results.data.TotalResults;

      console.log({ subscriptions });
    };

    onMounted(() => fetchSubscriptions(1));

    return {
      pagination,
      onChangePage,
      subscriptions,
      ...toRefs(state),
    };
  },
});
</script>

<style scoped>
.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
}
</style>
